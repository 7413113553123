import PropTypes from 'prop-types';

const pTypeFile = PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    lastModified: PropTypes.number
});

const FilePropTypes = {
    file: pTypeFile,
};
export default FilePropTypes;