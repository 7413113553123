import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FileUpload from './FileUpload'


import {
    onCancelAllUpload,
    cancelUpload,
    checkForError,
    checkFieldValidity,
    handleDroppedFiles,
    setSelectedDocType,
    loadDocTypes,
    setAllToDefault,
    setVerifyCallback,
    setExpiredCallback,
    getGoogleSiteKey,
    hideToast,
    onRecaptchaLoad,
    initializeUpload,
    setFormPath,
    registerRequiredFields
} from "./actions";

import {
    isValidFile,
    isValidReCaptcha,
    getFiles,
    getShowCancelMessage,
    getCancelMessage,
    isDefaultDocType,
    isDocTypeLoading,
    getDocTypeList,
    getErrorMessage,
    getErrorTitle,
    getShowUploadSuccess,
    getSuccessMessage,
    getShowUploadError,
    getSelectedDocType,
    getUserDetails,
    getUploadedFileName,
    getSiteKey,
    getShowCaptcha,
    getFileStatus,
    getResponseStatus,
    getRecaptchaStatus,
    getStartUpload,
    getUploadedFiles,
    getValidFilesCount,
    checkError,
    validField
} from "./selector";

const mapStateToProps = createStructuredSelector({
    validFile : isValidFile,
    validReCaptcha: isValidReCaptcha,
    files : getFiles,
    uploadedFiles: getUploadedFiles,
    showCancelMessage: getShowCancelMessage,
    cancelMessage: getCancelMessage,
    defaultDocType: isDefaultDocType,
    docTypeList: getDocTypeList,
    showUploadSuccess: getShowUploadSuccess,
    successMessage: getSuccessMessage,
    fileStatus: getFileStatus,
    showUploadError: getShowUploadError,
    errorMessage: getErrorMessage,
    errorTitle: getErrorTitle,
    docType : getSelectedDocType,
    userDetails : getUserDetails,
    uploadedFileName : getUploadedFileName,
    loading: isDocTypeLoading,
    siteKey: getSiteKey,
    showCaptcha: getShowCaptcha,
    responseStatus: getResponseStatus,
    recaptchaLoaded: getRecaptchaStatus,
    startUpload: getStartUpload,
    validFileCount : getValidFilesCount,
    fieldCheck : checkError,
    validField : validField
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    checkFieldValidity : (valid, fieldName) => dispatch(checkFieldValidity(valid, fieldName)),
    onDrop: (acceptedFiles, rejectedFiles) => dispatch(handleDroppedFiles(acceptedFiles, rejectedFiles)),
    onStartUpload: () => dispatch(initializeUpload()),
    onCancelUpload: () => dispatch(cancelUpload()),
    onCancelAllUpload: () => dispatch(onCancelAllUpload()),
    onCloseCancelMessage: () => dispatch(hideToast()),
    onDocTypeSelect : (doc) => dispatch(setSelectedDocType(doc)),
    loadDocTypes : () => dispatch(loadDocTypes()),
    setDefaultAll : () => dispatch(setAllToDefault()),
    onRecaptchaLoad : () => dispatch(onRecaptchaLoad()),
    verifyCallback: (reCaptchaToken) => dispatch(setVerifyCallback(reCaptchaToken)),
    expiredCallback: () => dispatch(setExpiredCallback()),
    getGoogleSiteKey: () => dispatch(getGoogleSiteKey()),
    checkForError : (fieldName, check) => dispatch(checkForError(fieldName, check)),
    setFormPath: (path) => dispatch(setFormPath(path)),
    registerRequiredFields : (fields) => dispatch(registerRequiredFields(fields))

})
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);