export const CustomAnalyticEvent = {
    CFD_IR : {
        name: 'CFD_IR_Name',
        email: 'CFD_IR_Email',
        documentType: 'CFD_IR_Document_Type',
        dropzone: 'CFD_IR_Drag_Drop',
        // Click_to_select: 'CFD_IR_Click_to_select',
        upload: 'CFD_IR_Upload'
    },
    CFD_LOAN_AGENT : {
        name: 'CFD_LOAN_AGENT_Name',
        email: 'CFD_LOAN_AGENT_Email',
        reference: 'CFD_LOAN_AGENT_Reference',
        dropzone: 'CFD_LOAN_AGENT_Drag_Drop',
        // Click_to_select: 'CFD_LOAN_AGENT_Click_to_select',
        upload: 'CFD_LOAN_AGENT_Upload'
    },

    CFD_LOAN_BORROWER : {
        name: 'CFD_LOAN_BORROWER_Name',
        email: 'CFD_LOAN_BORROWER_Email',
        reference: 'CFD_LOAN_BORROWER_Reference',
        documentType: 'CFD_LOAN_BORROWER_Document_Type',
        dropzone: 'CFD_LOAN_BORROWER_Drag_Drop',
        // Click_to_select: 'CFD_LOAN_BORROWER_Click_to_select',
        upload: 'CFD_LOAN_BORROWER_Upload'
    },
    CFD_LOAN_CREDIT : {
        name: 'CFD_LOAN_CREDIT_Name',
        email: 'CFD_LOAN_CREDIT_Email',
        reference: 'CFD_LOAN_CREDIT_Reference',
        documentType: 'CFD_LOAN_CREDIT_Document_Type',
        dropzone: 'CFD_LOAN_CREDIT_Drag_Drop',
        // Click_to_select: 'CFD_LOAN_CREDIT_Click_to_select',
        upload: 'CFD_LOAN_CREDIT_Upload'
    }

};

export const REPLACE_TOKENS = {
    TOKEN_DOC_TYPE: 'TOKEN_DOC_TYPE'
};

export const PATH_MAP = {
    '/' : 'CFD_IR',
    '/agent_notice' : 'CFD_LOAN_AGENT',
    '/credit_agreement' : 'CFD_LOAN_CREDIT',
    '/borrower' : 'CFD_LOAN_BORROWER'
}

export const CREDIT_DOC_TYPE = {
    'Credit Agreements/Amendments' : 'amendments',
    'Other Loan Documentation' : 'other',
    'Settlement Documents' : 'settlement_doc'
}

const {
    TOKEN_DOC_TYPE
} = REPLACE_TOKENS;

const analyticEvents = {
    CFD_IR_Name: {
        category: 'Citco File Drop IR',
        action: 'CFD_IR_Name',
        name: 'cfd_ir_name',
    },
    CFD_IR_Email: {
        category: 'Citco File Drop IR',
        action: 'CFD_IR_Email',
        name: 'cfd_ir_email',
    },
    CFD_IR_Document_Type: {
        category: 'Citco File Drop IR',
        action: 'CFD_IR_Document_Type',
        name: `cfd_ir_${TOKEN_DOC_TYPE}`,
        token: [TOKEN_DOC_TYPE],
    },
    CFD_IR_Drag_Drop: {
        category: 'Citco File Drop IR',
        action: 'CFD_IR_Drag_Drop',
        name: 'cfd_ir_drag_drop',
    },
    CFD_IR_Click_to_select: {
        category: 'Citco File Drop IR',
        action: 'CFD_IR_Click_to_select',
        name: 'cfd_ir_click_to_select',
    },
    CFD_IR_Upload: {
        category: 'Citco File Drop IR',
        action: 'CFD_IR_Upload',
        name: 'cfd_ir_Upload',
    },
    CFD_LOAN_AGENT_Name: {
        category: 'Citco File Drop Loan Agent',
        action: 'CFD_LOAN_AGENT_Name',
        name: 'cfd_loan_agent_name',
    },
    CFD_LOAN_AGENT_Email: {
        category: 'Citco File Drop Loan Agent',
        action: 'CFD_LOAN_AGENT_Email',
        name: 'cfd_loan_agent_email',
    },
    CFD_LOAN_AGENT_Reference: {
        category: 'Citco File Drop Loan Agent',
        action: 'CFD_LOAN_AGENT_Reference',
        name: 'cfd_loan_agent_reference',
    },
    CFD_LOAN_AGENT_Drag_Drop: {
        category: 'Citco File Drop Loan Agent',
        action: 'CFD_LOAN_AGENT_Drag_Drop',
        name: 'cfd_loan_agent_drag_drop',
    },
    CFD_LOAN_AGENT_Click_to_select: {
        category: 'Citco File Drop Loan Agent',
        action: 'CFD_LOAN_AGENT_Click_to_select',
        name: 'cfd_loan_agent_click_to_select',
    },
    CFD_LOAN_AGENT_Upload: {
        category: 'Citco File Drop Loan Agent',
        action: 'CFD_LOAN_AGENT_Upload',
        name: 'cfd_loan_agent_Upload',
    },
    CFD_LOAN_BORROWER_Name: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Name',
        name: 'cfd_loan_borrower_name',
    },
    CFD_LOAN_BORROWER_Email: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Email',
        name: 'cfd_loan_borrower_email',
    },
    CFD_LOAN_BORROWER_Reference: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Reference',
        name: 'cfd_loan_borrower_reference',
    },
    CFD_LOAN_BORROWER_Document_Type: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Document_Type',
        name: `cfd_loan_borrower_${TOKEN_DOC_TYPE}`,
        token: [TOKEN_DOC_TYPE],
    },
    CFD_LOAN_BORROWER_Drag_Drop: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Drag_Drop',
        name: 'cfd_loan_borrower_drag_drop',
    },
    CFD_LOAN_BORROWER_Click_to_select: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Click_to_select',
        name: 'cfd_loan_borrower_click_to_select',
    },
    CFD_LOAN_BORROWER_Upload: {
        category: 'Citco File Drop Loan Borrower',
        action: 'CFD_LOAN_BORROWER_Upload',
        name: 'cfd_loan_borrower_Upload',
    },
    CFD_LOAN_CREDIT_Name: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Name',
        name: 'cfd_loan_credit_agreement_name',
    },
    CFD_LOAN_CREDIT_Email: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Email',
        name: 'cfd_loan_credit_agreement_email',
    },
    CFD_LOAN_CREDIT_Reference: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Reference',
        name: 'cfd_loan_credit_agreement_reference',
    },
    CFD_LOAN_CREDIT_Document_Type: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Document_Type',
        name: `cfd_loan_credit_agreement_${TOKEN_DOC_TYPE}`,
        token: [TOKEN_DOC_TYPE],
    },
    CFD_LOAN_CREDIT_Drag_Drop: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Drag_Drop',
        name: 'cfd_loan_credit_agreement_drag_drop',
    },
    CFD_LOAN_CREDIT_Click_to_select: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Click_to_select',
        name: 'cfd_loan_credit_agreement_click_to_select',
    },
    CFD_LOAN_CREDIT_Upload: {
        category: 'Citco File Drop Loan Credit Agreement',
        action: 'CFD_LOAN_CREDIT_Upload',
        name: 'cfd_loan_credit_agreement_Upload',
    }
}

export default analyticEvents;