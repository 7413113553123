import axios from 'axios';

//axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';
const apiBaseURL = '/main';

class Http {


    CancelToken = axios.CancelToken;

    constructor() {
        const service = axios.create({});
        //service.interceptors.response.use(this.handleSuccess, this.handleError);

        this.service = service;
        this.service.defaults.timeout = 60 * 20 * 1000;
        this.apiBaseURL = apiBaseURL;
    }

    get(url){
        // let data = axios.get(apiBaseURL + url, {headers: { 'Content' : 'application/json' }})
        //
        // return data;

        return this.service.get(apiBaseURL + url,{
            headers: { 'Content' : 'application/json' }
        });
    }

    post(url, payload,){
        return this.service.post(apiBaseURL + url,
            payload, {
            headers: { 'Content' : 'application/json' }
        });
    }

    upload(url, file, progress, {
        // baseURL = apiBaseURL,
        ...rest
    } = {}) {
        const {cancelToken} = rest;
        return this.service.request({
            method: 'POST',
            data: file,
            url,
            // baseURL,
          //  timeout: 12000000,
          //  headers: { 'Content-Type': 'multipart/form-data' },

            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                progress(loaded, total);
            },
           cancelToken

        });
    }

    setCsrf(csrf){

        axios.defaults.headers.common[csrf.headerName] = csrf.token;
    }




}

const http = new Http();

export default http;