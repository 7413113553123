import {
  ADD_TOAST,
  REMOVE_TOAST,
} from './types';

const toastReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_TOAST:
      return [...state, payload];
    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload);
    default:
      return state;
  }
};

export default toastReducer;
