import React from 'react';
import PropTypes from 'prop-types';
import './UploadWarning.styles.scss';
import InvalidFile from './InvalidFile';
import {MAX_ACCEPTED_FILES, UPLOAD_STATUS_MAP} from "../../types";

export const UploadWarning = ({showInvalidFiles, invalidFiles, title, showDetails, type}) => {

    if(invalidFiles.length === 0 && type === UPLOAD_STATUS_MAP.INVALID){
        return null;
    }

    if(type === UPLOAD_STATUS_MAP.MAX_FILE){
        return (
            <div className='warning-upload-box'>
                <div className="line-top-border-warning"></div>
                <div className="container">
                    <div className="warning-message-container">
                        <span className="icon-container"><i className="fa-2x fa fa-exclamation-triangle"></i> </span>
                        <div className='warning-content'>
                            {'Max File Upload'}
                            <br/>
                            <span className='warning-details'>A maximum of {MAX_ACCEPTED_FILES} files can be uploaded at this time.</span>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    return(
        <div className='warning-upload-box'>
            <div className="line-top-border-warning"></div>
            <div className="container">
                <div className="warning-message-container">
                    <span className="icon-container"><i className="fa-2x fa fa-exclamation-triangle"></i> </span>
                    <div className='warning-content'>
                        {title}

                        {showInvalidFiles ?
                            <div><br/><InvalidFile invalidFiles={invalidFiles}/></div> : null
                        }
                        <br/><br/>
                        <span className='warning-details' onClick={() =>{showDetails(showInvalidFiles)}}>
                            <span>{showInvalidFiles ? 'Hide Details' : 'Show Details'}</span>
                        </span>

                    </div>
                </div>

            </div>

    </div>)
};

UploadWarning.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    showDetails: PropTypes.func,
    showInvalidFiles: PropTypes.bool
};

UploadWarning.defaultProps = {
    title: 'Some of your files are incompatible to upload'
};

export default UploadWarning;