import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';




const Icon = ({
  size, name, fixedWidth, className, activeClassName, handleOnClick, tooltip, tooltipClassName, id,
}) => {
  const iconClasses = classNames({
    [`fa-${size}`]: size,
    'fa-fw': fixedWidth,
    [`fa fa-${name}`]: name,
    [`${className}`]: className,
    [`${activeClassName}`]: activeClassName,
  });

  const hideTooltip = (e) =>{
      ReactTooltip.hide();
      handleOnClick(e);
  }

  if(tooltip !== null) {
      const randomId = String(Math.random());
      return (
          <Fragment>
            <span className='icon-container'>
              <i data-for={randomId}  className={iconClasses} onClick={hideTooltip} role="presentation" data-tip={tooltip}/>
            </span>
            <ReactTooltip id={randomId} type='info' place='right' effect='solid'
                          className={'tooltip ' + tooltipClassName} delayHide={0}/>
          </Fragment>
      );
  }else{
      return (
          <Fragment>
            <span className='icon-container'>
              <i id={id} className={iconClasses} onClick={handleOnClick} role="presentation"/>
            </span>
          </Fragment>
      );
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
  fixedWidth: PropTypes.bool,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipClassName: PropTypes.string

};

Icon.defaultProps = {
  id: null,
  fixedWidth: false,
  size: null,
  activeClassName: null,
  className: null,
  handleOnClick: () => {},
  tooltip: null,
  tooltipClassName: ''
};

export default Icon;
