import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'font-awesome/css/font-awesome.min.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import RootReducer from './rootReducer';
import thunk from 'redux-thunk';


import {getApplicationVersionNumber, getConfigData, initAnalytics} from './init';
import Footer from "./components/footer/Footer";
class Index extends Component{
    render(){
        if(this.props.config.configDetails === null){
            return <div className='not-found'><h2>{this.props.config.message}</h2></div>
        }
        const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunk)(createStore);
        const store = createStoreWithMiddleware(RootReducer);

        const versionNumber = this.props.version.versionNumber;
        const config = JSON.parse(this.props.config.configDetails);
        const formPath = this.props.config.formPath;

        return(
            <Provider store={store}>
                <App config={config} formPath={formPath}/>
                <Footer versionNumber={versionNumber}/>
            </Provider>
        )

    }
}



const renderApp = (formConfig, version) => {ReactDOM.render(<Index version={version} config={formConfig}/>, document.getElementById('root'))};
ReactDOM.render(<div style={{top:'25%', position:'absolute', left:'50%'}}><i className='fa fa-circle-o-notch fa-spin'/></div>, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


(async () => {
    const formPath = window.location.pathname
    const version =  await getApplicationVersionNumber();
    const formConfig = await getConfigData(formPath);
    const matomoConfig = await initAnalytics();
    renderApp(formConfig, version);
})();

