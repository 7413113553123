import React from 'react';
import PropTypes from 'prop-types';
import ListView from "../scenes/listItem/ListView";

const Menu = ({id, isLoading, items, onSelect}) => {

    let optionPanel = {};
    if(isLoading){
        optionPanel =   <i className='fa fa-circle-o-notch fa-spin'></i>
    }else{
        if(items.length > 0){
            optionPanel = <ListView items={items} onSelect={onSelect}/>// <ul id={'select_' + this.props.id + '_optionList'}>{options}</ul>
        }else{
            optionPanel = <label style={{marginLeft: '5px', fontSize:'10pt'}}>No Results Found.</label>
        }

    }
    return (<div className='data' id={'select_' + id + '_dataContainer'} >

                <div className='options' id={'select_' + id + '_options'}>
                    {optionPanel}
                </div>
            </div>
    );
}

Menu.propTypes ={
    id : PropTypes.string,
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    })),
    setSelected: PropTypes.func
}
export default Menu;