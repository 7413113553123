const numberToText = (num) => {
    const numTextMap = {
        3: 'three',
        5: 'five',
    };
    return numTextMap[num] || num;
};
export const whiteSpaceError = 'Cannot start with space.';
export const noCharacterError = 'Must contain at least one letter.';
export const maxLengthError = len => `Error. Max length ${len} reached.`;
export const minLengthError = len => `This field must contain at least ${numberToText(len)} characters.`;
export const requiredError = 'This field cannot be blank.';
export const invalidEmailMsg = 'Not a valid email address.';
export const invalidWindowChars = 'Not a valid windows file name.';
export const nonAlphabetAtStartMsg = 'Should start with a letter';
export const invalidCharacter = "Contains invalid character"
export const required = value => (value ? undefined : requiredError);

export const maxTo = max => (value = '') => ((value.length <= max) ? undefined : maxLengthError(max));
export const minFrom = min => (value = '') => ((value.length === 0 || value.length >= min) ? undefined : minLengthError(min));

export const containsACharacter = (value) => {
    const anyCharacter = /[a-zA-Z]/;
    const containsAnyCharacter = value.match(anyCharacter);
    return containsAnyCharacter ? null : noCharacterError;
};
export const maxLength = max => value =>{
    if(value !== ""){
        return value && value.length > max ? `Must be ${max} characters or less` : undefined;
    }

}


export const maxLength1000 = maxLength(1000);
export const maxLength100 = maxLength(100);
export const maxLength50 = maxLength(50);


export const noStartingWhiteSpaceName = (value) => {
    const whiteSpaceCheck = /^\s/;
    const whiteSpaceAtStart = value ? value.match(whiteSpaceCheck) : null;
    return whiteSpaceAtStart ? whiteSpaceError : null;
};

export const noStartingWhiteSpaceEmail = (value) => {
    const whiteSpaceCheck = /^\s/;
    const whiteSpaceAtStart = value ? value.match(whiteSpaceCheck) : null;
    return whiteSpaceAtStart ? whiteSpaceError : null;
};


export const startWithCharName = (value) => {
    const startWithCharPattern = /^[A-Za-z]/;
    const nonAlphabetAtStart = value ? value.match(startWithCharPattern) : null;
    return nonAlphabetAtStart ? null : nonAlphabetAtStartMsg;
};

export const startWithCharEmail = (value) => {
    const startWithCharPattern = /^[A-Za-z]/;
    const nonAlphabetAtStart = value ? value.match(startWithCharPattern) : null;
    return nonAlphabetAtStart ? null : "Email address is not valid";
};

export const validEmail = (email = '') => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return !re.test(String(email).toLowerCase()) ? "Email address is not valid" : undefined;
};

export const checkLocalPartLength = (email) =>{
    if(email.indexOf('@') > -1){
        const localPart = email.substring(0, email.lastIndexOf("@"));
        if(localPart.length > 64){
            return "Email address is not valid";
        }
    }else{
        if(email.length > 64){
            return "Email address is not valid";
        }
    }
};

export const checkDomainLength = (email) =>{
    if(email.split('@').pop() > -1){
        const localPart = email.substring(0, email.lastIndexOf("@"));
        if(localPart.length > 64){
            return "Email address is not valid";
        }
    }else{
        if(email.length > 64){
            return "Email address is not valid";
        }
    }
};

export const validChars = (value) => {
    const validAsciiChars = /^[ -~]+$/;
    const validChars = value ? validAsciiChars.test(value) : true;
    return validChars ? null : invalidCharacter;

}


export const noWindowsSpecialChars = value => (value && value.match(/[\?\/\\\<\>\|\:\"\*]+/) ? invalidWindowChars : null); //eslint-disable-line
