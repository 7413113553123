import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DropAreaFileUpload} from "./DropAreaFileUpload";

import {
    isValidFile,
    getFiles
} from "../../selector";
import {
    cancelUpload,
    deleteFile
} from "../../actions";


const mapStateToProps = createStructuredSelector({
    validFile : isValidFile,
    files : getFiles
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    onDeleteFile: (filename) => dispatch(deleteFile(filename)),
    onCancelUpload: (filename) => dispatch(cancelUpload(filename))

})

export default connect(mapStateToProps, mapDispatchToProps)(DropAreaFileUpload);