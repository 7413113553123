import React, {Component} from 'react';
import './App.scss';
import FileUpload from './scenes/file-upload';
import PropTypes from 'prop-types';
import Toasts from "./scenes/Toasts";


class App extends Component {

    render() {
        return (
                <div className=" App-logo" >
                    <div className="citco-logo">
                        <img src={require("./resources/images/logo-login.png")} alt="Cinque Terre" width="200" height="300" />
                    </div>
                    <div className='container' style={{paddingBottom:'12px'}}>
                        <FileUpload uploadForm={this.props.config} formPath={this.props.formPath}/>
                    </div>
                    <Toasts/>
                </div>

        );
    }
}


App.propTypes = {
    versionNumber: PropTypes.string
}

export default App;
