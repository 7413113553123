import trackedEvents, {REPLACE_TOKENS} from "./trackedEvents";

const {
    TOKEN_DOC_TYPE,
} = REPLACE_TOKENS;

const replaceToken = (event, payload) => {

    try {
        if (event !== undefined && event.token.length > 0) {
            let eventName = event.name;
            if (event.token.includes(TOKEN_DOC_TYPE)) {
                const docType = payload.toLowerCase();
                eventName = eventName.replace(TOKEN_DOC_TYPE, docType);
            }

            return { ...event, name: eventName };
        }
    } catch {}
    return event;
};

export const pushEvents = (actionType, payload) => {

    const events = replaceToken(trackedEvents[actionType], payload);
    if (events) {
        const paq = window._paq || [];
        paq.push([
            'trackEvent',
            events.category,
            events.action,
            events.name,
            events.value,
        ]);
        paq.push(['trackPageView']);
    }
};

export default pushEvents;