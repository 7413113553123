import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../common/FontAwesomeIcon';
import LOG_LEVEL from './Constants';
import './styles.scss';
import {UPLOAD_STATUS_MAP} from "../../scenes/file-upload/types";

const shouldAutoRemoveToast = persist => !persist;

class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.removeToast = this.removeToast.bind(this);
    this.state = {
      removed: false,
    };

    const { persist, delay } = props.options;
    if (shouldAutoRemoveToast(persist)) {
      setTimeout(() => { this.removeToast(); }, delay);
    }
  }

  removeToast() {
    const { killToast, id } = this.props;
    this.setState({ removed: true });
    setTimeout(() => killToast(id), 1000);
  }

  render() {
    const { id, message, options } = this.props;
    const { logLevel } = options;
    const { removed } = this.state;
    let customizedMessage = <p>Your file <b>{'"' + message + '"'}</b> has been cancelled and will not be uploaded.</p>
    if(id === UPLOAD_STATUS_MAP.CANCELED){
      customizedMessage = message;
    }

    if(logLevel === LOG_LEVEL.WARNING){
        customizedMessage = <p>The following files are incompatible to upload: {message} </p>
    }

    const toasterClass = classNames({
      'toast-container': true,
        [`${logLevel.toLowerCase()}`]: true,
      animated: true,
      fadeInUp: !removed,
      fadeOutUp: removed,
    });


    return (
      <div id={`toast-${id}`} className={toasterClass}>
        <span id="toast-message" className="toast-message">
          <span id="toast-desc">
            { customizedMessage }
          </span>
        </span>
        <Icon handleOnClick={this.removeToast} className="close-toast" name="times-circle" size="lg" />
      </div>
    );
  }
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  killToast: PropTypes.func.isRequired,
  options: PropTypes.shape({
    logLevel: PropTypes.string,
    delay: PropTypes.number,
    persist: PropTypes.bool,
  }),
};
Toast.defaultProps = {
  options: {
    logLevel: LOG_LEVEL.INFO,
    persist: false,
    delay: 5000,
  },
};

const Toasts = ({ toasts, killToast }) => (
  <div id="toasts-wrapper" className="toasts-wrapper">
    {
      toasts.map(({
        message, id, options,
      }) => (
        <Toast
          key={id}
          message={message}
          id={id}
          killToast={killToast}
          options={options}
        />
      ))
    }
  </div>
);
Toasts.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      delay: PropTypes.number,
      persist: PropTypes.bool,
      logLevel: PropTypes.string,
    }),
  })),
  killToast: PropTypes.func.isRequired,
};
Toasts.defaultProps = {
  toasts: [],
};

export { Toast };
export default Toasts;
