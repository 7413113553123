const initialState = {
    item: {}
}

const selectReducer = (state = initialState, action) =>{

    switch (action.type) {
        case 'SET_SELECTED':
            return {
                ...state,
                item: action.payload
            }
        default :
            return state;
    }
}

export default selectReducer;