import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/FontAwesomeIcon';
import './styles.scss';

const Button = ({
                    id,
                    children,
                    className,
                    loading,
                    buttonText,
                    onClick,
                    ...rest
                }) => (
    <button type="button" className={className} onClick={onClick} id={id} {...rest}>
        { buttonText }
        { loading ? <Icon size="lg" name="circle-o-notch" className="fa-spin" /> : children }
    </button>
);

Button.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    loading: PropTypes.bool,
    buttonText: PropTypes.string,
};

Button.defaultProps = {
    children: undefined,
    className: 'btn-c1-primary',
    loading: false,
    buttonText: null,
};

export default Button;
