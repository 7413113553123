import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../components/common/FontAwesomeIcon';
import {fileSize} from "../../../../utils/fileUtil";
import FilePropTypes from '../../proptypes';
import ProgressBar from '../ProgressBar';
import {UPLOAD_STATUS_MAP} from "../../types";

const ValidFile = ({file, handleDelete, status, progress, handleCancel, showBorder}) =>{

    let fileTypeIcon = 'file-image-o';
    if(file.type === 'application/pdf'){
        fileTypeIcon = 'file-pdf-o'
    }

    let progressBar = null;
    let actionIcon = null;
    if(status === UPLOAD_STATUS_MAP.DEFAULT){
        actionIcon = <div className='btn-panel'>
                        <Icon name='trash-o' handleOnClick={(e) => {e.preventDefault(); handleDelete(file.name)}} size='2x' tooltip='DELETE'></Icon>
                    </div>
    }else if(status === UPLOAD_STATUS_MAP.STARTED){
        progressBar = <ProgressBar value={progress}/>;
        actionIcon = <div className={'btn-panel' + ((progress < 100) ? '' :  ' status-icon-disabled') }>
                        <Icon name='times-circle' handleOnClick={(e) => {e.preventDefault(); if(progress < 100){handleCancel(file.name)}}} size='2x' tooltip={(progress < 100) ? 'CANCEL' : ''}></Icon>
                    </div>
    }else if(status === UPLOAD_STATUS_MAP.CANCELED){
        actionIcon = <div className='btn-panel status-icon-failed'>
                        <Icon name='ban' handleOnClick={(e) => {e.preventDefault(); }} size='2x' tooltip='CANCELED'></Icon >
                    </div>
    }else if(status === UPLOAD_STATUS_MAP.FAILED){
        actionIcon = <div className='btn-panel status-icon-failed'>
                        <Icon name='exclamation-circle' handleOnClick={(e) => {e.preventDefault(); }} size='2x' tooltip='FAILED'></Icon>
                    </div>
    }else if(status === UPLOAD_STATUS_MAP.SUCCESS){
        actionIcon = <div className='btn-panel status-icon-success'>
                        <Icon name='check-circle' handleOnClick={(e) => {e.preventDefault();}} size='2x' tooltip='SUCCESS'></Icon>
                    </div>
    }else if(status === UPLOAD_STATUS_MAP.FILE_RECEIVED){
        actionIcon = <div className='btn-panel status-icon-success'>
            <Icon name='check-circle' handleOnClick={(e) => {e.preventDefault();}} size='2x' tooltip='SUCCESS'></Icon>
        </div>
    }else if(status === UPLOAD_STATUS_MAP.MALICIOUS_FILE) {
        actionIcon = <div className='btn-panel'>
                        <Icon name='exclamation-triangle' handleOnClick={(e) => {e.preventDefault();}} size='2x' tooltip='MALICIOUS FILE'></Icon>
                    </div>
    }

    let border = '';
    if(showBorder){
        border = 'row-border';
    }

    return(
      <Fragment>

              <div className={'valid-file-info ' + border}>

                  <Icon name={fileTypeIcon} size='2x' className='file-type'/>
                  <div className='details' title={file.name}>
                      <span id='name'>{file.name}</span>
                      <span id='size'>{fileSize(file.size)}</span>

                  </div>
                  {progressBar}
                  {actionIcon}

              </div>

      </Fragment>
    );



}





ValidFile.propTypes = {
    file : FilePropTypes.file,
    handleDelete : PropTypes.func
}

ValidFile.defaultProps = {
    file : {},
    handleDelete: () =>{}
}

export default ValidFile;