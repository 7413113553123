import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {UploadWarning} from "./UploadWarning";

import {
    getShowInvalidFiles,
    getInvalidFiles,
    getWarningType
} from "../../selector";
import {
    showInvalidFiles
} from "../../actions";


const mapStateToProps = createStructuredSelector({
    showInvalidFiles : getShowInvalidFiles,
    invalidFiles : getInvalidFiles,
    type : getWarningType
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    showDetails: (show) => dispatch(showInvalidFiles(show))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadWarning);