import React from 'react';
import './styles.scss';
import isBlank from 'underscore.string/isBlank';

const WarningField = (props) => {

    const { input, label, type, disabled, validateForm, withError, single,  required, meta: { error, warning, valid, dirty, visited, touched} } = props;
    let hasError = "none";
    let icon = "";
    let border = "";

    if(withError){
        if(error !== undefined && error && required && visited){
            hasError = "error";
            icon = "fa-exclamation-circle";
            border = "1px solid #c94040";
            validateForm(false, input.name);
        }else if(error !== undefined && error && dirty){
            hasError = "error";
            icon = "fa-exclamation-circle";
            border = "1px solid #c94040";
            validateForm(false, input.name);
        }else if(valid && dirty && !isBlank(input.value)){
            icon = "fa-check-circle";
            validateForm(true, input.name);
        }else{
            if(required){
                validateForm(false, input.name);
            }else{
                validateForm(true, input.name);
            }

        }

        if(disabled){
            border = "1px solid #444649";
        }
    }

    let inputField = " multi";
    let errorField = "";
    if(single){
        inputField = " single";
        errorField = " single-error"
    }

    return(

        <div className="redux-form-input email-input-style">
            <label>{label}</label>
            <div>
                <div className={"field-panel input-field-" + hasError}>
                    <input className={"text-input disabled-" + disabled + inputField} type={type} style={{border:border}} disabled={disabled} {...input}/>
                    <span className="icon-container"><i className={"fa-2x fa " + icon}></i> </span></div>
                <div className={"error-container "+ hasError + errorField}>{((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}</div>
            </div>
        </div>
    );
}


export default WarningField;