/* eslint-disable no-underscore-dangle, no-undef, no-restricted-globals */

import analyticPaths from "./trackedPaths";

const pushToMatomo = (analyticPage, clientIp) => {
    const paq = window._paq || [];
    // paq.push(['setUserId', clientIp]);
    paq.push(['setCustomUrl', `${analyticPage}`]);
    paq.push(['trackPageView']);
};

export const pushAnalytics = (url, clientIp) => {
    analyticPaths.forEach((path) => {
        if (url.match(path.urlPathRegex)) {
            pushToMatomo(path.analyticsPage, clientIp);
        }
    });
};

export default pushAnalytics;
