import React from 'react';
import PropTypes from 'prop-types';
import './UploadError.styles.scss';

export const UploadError = ({title, errorMessage, responseStatus}) => {

    let message = <div className="error-message">
                    {errorMessage}
                  </div>
    if(responseStatus === 403){
        message = <div className="error-message">
                     Your file cannot be uploaded at this time. Please contact support at <a href="mailto:citcohelp@citco.com">citcohelp@citco.com</a>.
                  </div>
    }else if(responseStatus >= 500){
        message = <div className="error-message">
            An unexpected error has occurred. Please contact support at <a href="mailto:citcohelp@citco.com">citcohelp@citco.com</a>.
        </div>
    }

    return (

        <div className="upload-error-container">
            <div className='error-upload-box'>
                <div className="line-top-border"></div>
                <div className="container">
                    <div className="error-message-container">
                        <span className="icon-container"><i className="fa-2x fa fa-exclamation-circle"></i> </span>
                        <div className="error-content">
                            {title}
                            <br/>
                            {message}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};


UploadError.propTypes = {
    errorMessage: PropTypes.string,
    title: PropTypes.string,
    responseStatus: PropTypes.number
};

UploadError.defaultProps = {
    errorMessage: 'Unexpected error occurred, please contact support.'
};

export default UploadError;