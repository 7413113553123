import {
  ADD_TOAST,
  REMOVE_TOAST,
} from './types';

export const addToast = (id, message, {persist, delay, logLevel }) => ({
  type: ADD_TOAST,
  payload: {
    id: id,
    message :  message,
    options: {
      logLevel: logLevel,
      delay: !delay && !persist ? 5000 : delay
    }
  },
});
export const killToast = payload => ({
  type: REMOVE_TOAST,
  payload,
});
