import { createSelector } from 'reselect';
import _ from 'lodash';

export const getFileUpload = state => state.fileUpload;
export const getForm = state => state.form.FileUploadForm;

export const isValidFile = createSelector(
    getFileUpload,
    fileUpload => fileUpload.validFile
);

export const getFiles = createSelector(
    getFileUpload,
    fileUpload => fileUpload.files
);

export const getUploadedFiles = createSelector(
    getFileUpload,
    fileUpload => fileUpload.uploadedFiles
);

export const getShowCancelMessage = createSelector(
    getFileUpload,
    fileUpload => fileUpload.showCancelMessage
);

export const getCancelMessage = createSelector(
    getFileUpload,
    fileUpload => fileUpload.cancelMessage
);

export const isDefaultDocType = createSelector(
    getFileUpload,
    fileUpload => fileUpload.defaultDocType
);

export const isDocTypeLoading = createSelector(
    getFileUpload,
    fileUpload => fileUpload.loading
);

export const getDocTypeList = createSelector(
    getFileUpload,
    fileUpload => fileUpload.docTypeList
);

export const getShowUploadSuccess = createSelector(
    getFileUpload,
    fileUpload => fileUpload.showUploadSuccess
);

export const getSuccessMessage = createSelector(
    getFileUpload,
    fileUpload => fileUpload.successMessage
);

export const getShowUploadError = createSelector(
    getFileUpload,
    fileUpload => fileUpload.showUploadError
);

export const getSelectedDocType = createSelector(
    getFileUpload,
    fileUpload => fileUpload.docType
);

export const getUploadedFileName = createSelector(
    getFileUpload,
    fileUpload => fileUpload.uploadedFileName
);

export const getErrorMessage = createSelector(
    getFileUpload,
    fileUpload => fileUpload.errorMessage
);
export const getErrorTitle = createSelector(
    getFileUpload,
    fileUpload => fileUpload.errorTitle
);

export const getUserDetails = createSelector(
    getForm,
    form => {
        let userDetails = {};
        if(form){
            const fields = _.keys(form.registeredFields)
            for (let i = 0; i < fields.length; i++){
                userDetails[fields[i]] = ((form.values && form.values[fields[i]]) ? form.values[fields[i]] : '')
            }
        }
        return userDetails;
    }
);

export const validField = createSelector(
    getFileUpload,
    fileUpload => {
        let allValid = true;
        if(!_.isEmpty(fileUpload.validField)){
            allValid = _.every(fileUpload.validField, (field)  => field === true)
        }
        return allValid;
    }
);

export const isValidReCaptcha = createSelector(
    getFileUpload,
    fileUpload => fileUpload.validReCaptcha
);

export const getSiteKey = createSelector(
    getFileUpload,
    fileUpload => fileUpload.siteKey
);

export const getShowCaptcha = createSelector(
    getFileUpload,
    fileUpload => fileUpload.showCaptcha
);

export const getRecaptchaToken = createSelector(
    getFileUpload,
    fileUpload => fileUpload.recaptchaToken
);

export const getFileStatus = createSelector(
    getFileUpload,
    fileUpload => fileUpload.fileStatus
)

export const getResponseStatus = createSelector(
    getFileUpload,
    fileUpload => fileUpload.responseStatus
)

export const getRecaptchaStatus = createSelector(
    getFileUpload,
    fileUpload => fileUpload.recaptchaLoaded
)

export const getStartUpload = createSelector(
    getFileUpload,
    fileUpload => fileUpload.startUpload
)

export const getShowInvalidFiles = createSelector(
    getFileUpload,
    fileUpload => fileUpload.showInvalidFiles
)

export const getInvalidFiles = createSelector(
    getFileUpload,
    fileUpload => fileUpload.invalidFiles
)

export const getWarningType = createSelector(
    getFileUpload,
    fileUpload => fileUpload.warningType
)

export const getValidFilesCount = createSelector(
    getFileUpload,
    fileUpload =>  _.keys(fileUpload.files).length
)

export const getCancelUpload = createSelector(
    getFileUpload,
    fileUpload => fileUpload.cancelUpload
)

export const checkError = createSelector(
    getFileUpload,
    fileUpload => {
        return {name: fileUpload.name, email: fileUpload.email, reference: fileUpload.reference}
    }
)

export const getFormPath = createSelector(
    getFileUpload,
    fileUpload => fileUpload.formPath
)

export const getRequiredFields = createSelector(
    getFileUpload,
    fileUpload => fileUpload.requiredFields
)

