import http from '../../service/http'
import {reset} from 'redux-form';
import {
    createAcceptedFileObject,
    initAcceptedFiles
} from "../../utils/fileUtil";
import {addToast} from "../Toasts/actions";
import _ from 'lodash';
import {
    ACCEPTED_FILES,
    REJECTED_FILES,
    DEFAULT_DROP_AREA,
    START_UPLOAD,
    SET_SELECTED_DOC_TYPE,
    SET_DOC_TYPE_LIST,
    DOC_TYPE_LIST_ERROR,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_ERROR,
    UPDATE_FILES,
    DEFAULT,
    LOADING_DOCS,
    SET_RECAPTCHA_TOKEN,
    SET_EXPIRED_RECAPTCHA,
    SET_SITE_KEY,
    HIDE_TOAST,
    RECAPTCHA_LOADED,
    DELETE_FILE,
    UPLOAD_STATUS_MAP,
    SHOW_INVALID_FILES,
    CANCEL_UPLOAD,
    MAX_ACCEPTED_FILES, SORT_STATUS_MAP, FILE_UPLOAD_PROGRESS, FILE_UPLOAD_INIT,
    CHECK_FIELD_ERROR, FORM_PATH, VALID_FIELD, REQUIRED_FIELDS
} from "./types";

import {
    getFiles,
    getUserDetails,
    getSelectedDocType,
    getRecaptchaToken,
    getStartUpload,
    getCancelUpload,
    getFormPath, getRequiredFields
} from "./selector";
import LOG_LEVEL from "../../components/Toasts/Constants";

let cancelTokens = {};
let maxUploadCount = 3;
let stagedFiles = [];
let fileUploadedCount = 0;


export class fileUploadApiCall {
    static async upload(url, file, onProgress, cancelToken) {

        const { data } = await http.upload(url, file, onProgress, cancelToken);
        return data;
    }
}

export function makeUploadRequest(file, meta, recaptchaToken) {
    let data = new FormData();

    data.append('username', meta.name);
    data.append('userEmail', meta.email);
    data.append('file', file, file.name);
    data.append('fileName', file.name);
    data.append('fileSize', file.size)
    data.append('recaptchaToken', recaptchaToken);
    data.append('formPath', meta.formPath);
    if(meta.hasOwnProperty('reference')){
        data.append('reference', meta.reference);
    }
    if(meta.docType){
        data.append('documentType',  meta.docType.documentType);
        data.append('documentId', meta.docType.documentId);
    }

    return data;
}

export const handleDroppedFiles = (acceptedFiles, rejectedFiles) => (dispatch, getState) =>{

    if(getStartUpload(getState()))
        return;
    if(acceptedFiles.length > 0){
        dispatch(handleAcceptedFiles(acceptedFiles, rejectedFiles))
    }
   if(rejectedFiles.length > 0){
        dispatch(handleRejectedFiles(rejectedFiles));

   }
}

export const handleAcceptedFiles = (files, rejectedFiles) => (dispatch, getState)  =>{

    let currentFiles = getFiles(getState());
    if((_.keys(currentFiles).length + files.length) > MAX_ACCEPTED_FILES){
        dispatch({
            type: UPLOAD_STATUS_MAP.MAX_FILE
        })
    }else{
        let accepted = createAcceptedFileObject(files, currentFiles);

        dispatch({
            type: ACCEPTED_FILES,
            payload: {files : accepted, validFile: true, invalidFiles: rejectedFiles}
        })
    }

}

export const handleRejectedFiles = (files) =>{

    return({
        type: REJECTED_FILES,
        payload: {files : files, showInvalidFiles: false}
    })
}


export const deleteFile = (fileName) => (dispatch, getState) =>{
    let acceptedFiles = getFiles(getState());
    let updatedFiles =  _.omit(acceptedFiles, [fileName]);

    if(!_.isEmpty(updatedFiles)){
        dispatch({
            type: DELETE_FILE,
            payload: {files : updatedFiles, validFile: true}
        })
    }else {
        dispatch({
            type: DEFAULT_DROP_AREA,
            payload: {
                showCancelMessage: false,
                cancelMessage: ''
            }
        })
    }
}

export const hideToast = () =>{
    return({
        type: HIDE_TOAST
    })
}

export const uploadProgress = (dispatch, getState, fileName) => (loaded, total) =>{

    let progress = Math.round((loaded * 100) / total);
    if(progress < 5){
        progress = 5;
    }

    dispatch({
        type: FILE_UPLOAD_PROGRESS,
        payload: {fileName: fileName, progress: progress}
    })

}

export const fileUploadSuccess = (dispatch, name, response) => {

    dispatch ({
        type: UPDATE_FILES,
        payload: {fileName: name, update:{
                status: response.statusCode,
                response : response,
                sortStatus: SORT_STATUS_MAP[response.statusCode]
            }}
    });

    fileUploadedCount-=1;
    uploadNextFiles(dispatch);

    if(fileUploadedCount === 0){
        // dispatch UPLOAD SUMMARY
        dispatch({
            type: FILE_UPLOAD_SUCCESS
        })
    }


};

export const fileUploadError = (dispatch, getState, name, response) => {

    const currentFiles = getFiles(getState());

    if(!_.isEmpty(currentFiles) && (currentFiles[name].status !== UPLOAD_STATUS_MAP.CANCELED)) {
        dispatch({
            type: UPDATE_FILES,
            payload: {
                fileName: name, update: {
                    status: UPLOAD_STATUS_MAP.FAILED,
                    response: response,
                    sortStatus: SORT_STATUS_MAP.FAILED
                }
            }
        });
    }
    fileUploadedCount-=1;
    uploadNextFiles(dispatch);

    let cancelledAll = true;
    _.forOwn(currentFiles, (file) =>{
        if(file.status !== UPLOAD_STATUS_MAP.CANCELED){
            cancelledAll = false;
            return false;
        }
    });

    if(!cancelledAll) {
        if (fileUploadedCount === 0 && !getCancelUpload(getState())) {
            // dispatch UPLOAD SUMMARY
            dispatch({
                type: FILE_UPLOAD_SUCCESS
            })
        }
    }else{
        dispatch({
            type: DEFAULT_DROP_AREA,
            payload: {
                showUploadSuccess: false,
                showCancelMessage: true
            }
        });
    }
};

export const checkFieldValidity = (valid, fieldName) => (dispatch) =>{
    dispatch({
        type: VALID_FIELD,
        payload: {field : fieldName, valid: valid}
    })
}

export const checkForError = (fieldName, check) => (dispatch) =>{
    dispatch({
        type: CHECK_FIELD_ERROR,
        payload: {
            field: fieldName,
            check: check
        }
    });
}

export const initializeUpload = () => (dispatch, getState) =>{
    cancelTokens = {};
    stagedFiles = [];

    const currentFiles = getFiles(getState());
    fileUploadedCount = _.keys(currentFiles).length;

    const initFiles = initAcceptedFiles(currentFiles);

    dispatch({
        type: FILE_UPLOAD_INIT,
        payload:{files: initFiles}
    });
    dispatch({
        type: START_UPLOAD
    });
}

export const startFileUpload = () => (dispatch, getState) =>{

    const currentFiles = getFiles(getState());
    let userDetails = getUserDetails(getState());
    const docType = getSelectedDocType(getState());
    const recaptchaToken = getRecaptchaToken(getState());
    const formPath = getFormPath(getState())

    userDetails['docType'] = docType;
    userDetails['formPath'] = formPath;

    let fileCount = 0;
    _.forOwn(currentFiles, (item, key) => {
        if(item.status !== UPLOAD_STATUS_MAP.CANCELED){
            if(fileCount < maxUploadCount){
                fileCount += 1;
                dispatch(uploadFile(item.file, userDetails, recaptchaToken));
            }else{
                stagedFiles.push({fileUpload : uploadFile(item.file, userDetails, recaptchaToken), name: key});
            }
        }
    })
    fileCount = 0;

}

export const uploadNextFiles = (dispatch) =>{

    if(stagedFiles.length > 0) {
        dispatch(stagedFiles[0].fileUpload);
        stagedFiles.shift();
    }

}

export const uploadFile = (file, meta, recaptchaToken) => async (dispatch, getState) => {
        cancelTokens[file.name] = http.CancelToken.source();
        const requestData = makeUploadRequest(file, meta, recaptchaToken);
            try {
                const response = await fileUploadApiCall.upload(
                    '/upload',
                    requestData,
                    uploadProgress(dispatch, getState, file.name),
                    { cancelToken: cancelTokens[file.name].token });
                fileUploadSuccess(dispatch, file.name, response);
            } catch (e) {
                fileUploadError(dispatch, getState, file.name, e.response.data);
            }
}

export const cancelFileUpload = (name, dispatch) =>{
    if(cancelTokens[name]){
        cancelTokens[name].cancel(name);
    }else{
        fileUploadedCount-=1;
    }
    dispatch(addToast(name, name, {delay: 5000, logLevel: LOG_LEVEL.INFO} ));

}

export const cancelUpload = (fileName) => (dispatch) =>{

    dispatch({
        type: UPDATE_FILES,
        payload: {
            fileName: fileName, update: {
                status: UPLOAD_STATUS_MAP.CANCELED,
                response: {},
                sortStatus: SORT_STATUS_MAP.CANCELED
            }
        }
    });

    stagedFiles = stagedFiles.filter(fileDetails => fileDetails.name !== fileName);
    cancelFileUpload(fileName, dispatch);

}

export const onCancelAllUpload = () => (dispatch, getState) =>{

    dispatch({
        type: CANCEL_UPLOAD
    })

    stagedFiles = [];
    let uploadedFiles = getFiles(getState());
    let uploadCount = 0;

    _.mapValues(uploadedFiles, (item, key) => {
        if (item.status === UPLOAD_STATUS_MAP.STARTED) {
            if(cancelTokens[key]){
                cancelTokens[key].cancel(key);
            }
            item.status = UPLOAD_STATUS_MAP.CANCELED;

        }else if(item.status === UPLOAD_STATUS_MAP.SUCCESS ||
            item.status === UPLOAD_STATUS_MAP.FAILED){
            uploadCount++;
        }
    });

    dispatch({
        type: FILE_UPLOAD_INIT,
        payload:{files: uploadedFiles}
    });

    if(uploadCount > 0){
        dispatch({
            type: FILE_UPLOAD_SUCCESS
        })
    }else{
        dispatch({
            type: DEFAULT_DROP_AREA,
            payload: {
                showUploadSuccess: false,
                showCancelMessage: true
            }
        });
    }

    dispatch(addToast(UPLOAD_STATUS_MAP.CANCELED, 'All pending and in-progress uploads have been cancelled.', {delay: 5000, logLevel: LOG_LEVEL.INFO} ));

}

export const setSelectedDocType = (doc) => (dispatch, getState) =>{
    checkRequiredFieldValidity(getState, dispatch);

    dispatch({
        type: SET_SELECTED_DOC_TYPE,
        payload:{
            default : false,
            docType: doc
        }
    })
}

export const setVerifyCallback = (reCaptchaToken) => async (dispatch) => {

    try {

        const {data} = await http.post('/verifyRecaptcha', {token: reCaptchaToken});

        if(data['success']){
            dispatch({
                type: SET_RECAPTCHA_TOKEN,
                payload: {recaptchaToken: reCaptchaToken}
            });

            dispatch(startFileUpload());

        }else{
            dispatch({
                type: FILE_UPLOAD_ERROR,
                payload: {errorMessage: 'Captcha validation failed.', title: 'Error'}
            });
        }

    }catch (e) {
        dispatch({
            type: FILE_UPLOAD_ERROR,
            payload: {errorMessage: 'Captcha validation failed.', title: 'Error'}
        });
    }

}

export const setExpiredCallback = () =>{
    return({
        type: SET_EXPIRED_RECAPTCHA,
        payload:{
            validReCaptcha : false
        }
    })
}

export const loadDocTypes = () => async (dispatch) =>{

    dispatch({type: LOADING_DOCS});
    try {
        const {data} = await http.get('/getAllowedDocumentType');
        dispatch({
            type: SET_DOC_TYPE_LIST,
            payload: {docTypes: data, loading: false}
        });
    }catch (e) {
        if(e.response){
            dispatch({
                type: DOC_TYPE_LIST_ERROR,
                payload: {docTypes: [], loading: false, errorMessage: e.response.data.message, responseStatus: e.response.status}
            });
        }else if(e.request){
            dispatch({
                type: DOC_TYPE_LIST_ERROR,
                payload: {docTypes: [], loading: false, errorMessage: "Request has timed out.", responseStatus: e.response.status}
            });
        }else{
            dispatch({
                type: DOC_TYPE_LIST_ERROR,
                payload: {docTypes: [], loading: false, errorMessage: '', responseStatus: e.response.status}
            });
        }

    }

}

export const getGoogleSiteKey = () => async (dispatch) =>{
    try {
        const {data} = await http.get('/siteKey');
        dispatch({
            type: SET_SITE_KEY,
            payload: {siteKey: data.siteKey}
        });

    }catch (e) {
        if(e.response){
            dispatch(fileUploadError(e.response.data.message, '', e.response.status, 'Error'))
        }else if(e.request){
            dispatch(fileUploadError("Request has timed out.", '', e.response.status, 'Error'))
        }else{
            dispatch(fileUploadError('', '', e.response.status, 'Error'))
        }

    }
}

export const setAllToDefault = () => (dispatch, getState) =>{

    dispatch(reset('FileUploadForm'));
    dispatch({
        type : DEFAULT
    })
    // checkRequiredFieldValidity(getState, dispatch);
}

export const onRecaptchaLoad = () => (dispatch) =>{
    dispatch({
        type: RECAPTCHA_LOADED
    })
}

export const showInvalidFiles = (show) => (dispatch) =>{
    dispatch({
        type: SHOW_INVALID_FILES,
        payload: {show: !show}
    })
}

export const setFormPath = (path) => (dispatch) =>{
    dispatch({
        type: FORM_PATH,
        payload : {path: path}
    })
}

export const registerRequiredFields = (fields) => (dispatch) =>{
    let requiredFields = [];

    for(let i=0; i<fields.length; i++){
        if((_.has(fields[i].props, 'required')) && fields[i].props.required){
            requiredFields.push(fields[i].props.id);
        }
    }

    dispatch({
        type: REQUIRED_FIELDS,
        payload: {requiredFields : requiredFields}
    })

}

const checkRequiredFieldValidity = (getState, dispatch) =>{
    const userDetails = getUserDetails(getState());
    const requiredFields = getRequiredFields(getState());
    if(requiredFields.length > 0) {
        for (let i = 0; i < requiredFields.length; i++) {
            if (_.has(userDetails, requiredFields[i]) && userDetails[requiredFields[i]] === '') {
                dispatch({
                    type: VALID_FIELD,
                    payload: {field: requiredFields[i], valid: false}
                })
            }
        }
    }

}