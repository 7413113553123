import React from 'react';
import PropTypes from 'prop-types';
import './footer.scss';



const Footer = ({ versionNumber }) => (
    <footer className="hide-for-print footer site-footer">
        <div className="footer-content">
            <div >
                <div >
                        <div className={"assistance-container"}>
                            <p>For assistance, please contact your Citco account representative or email <a href="mailto:citcohelp@citco.com">citcohelp@citco.com</a></p>
                            <p>Alternatively, Phone +1 201-793-5858 or +41 22-591-1212 (Option 3)</p>
                            <br/>
                            <Version versionNumber={versionNumber}/>
                        </div>

                        <div className={"toll-free-container"}>
                            <table>
                                <tbody>
                                <tr><td>Toll Free</td><td></td></tr>
                                <tr><td>USA</td><td>1-800-457-2251</td></tr>
                                <tr><td>Ireland</td><td>1-800-570-015</td></tr>
                                <tr><td>UK</td><td>08-001-693-105</td></tr>
                                <tr><td>SNG & HK</td><td>011-800-4848-0000</td></tr>
                                </tbody>
                            </table>

                        </div>

                </div>

            </div>
        </div>

    </footer>
);

export const Version = ({ versionNumber }) => (
    <div className="version-container" style={{paddingTop:'20px', float:'left'}}>
        <span>Version {versionNumber} {` Copyright ${new Date().getFullYear()} CITCO. All Rights Reserved`}</span>
    </div>
);


Version.propTypes = {
    versionNumber: PropTypes.string,
};

Version.defaultProps = {
    versionNumber: '',
};


Footer.propTypes = {
    versionNumber: PropTypes.string,
};

Footer.defaultProps = {
    versionNumber: '',
};


export default Footer;