import {
    ACCEPTED_FILES,
    REJECTED_FILES,
    DEFAULT_DROP_AREA,
    START_UPLOAD,
    SET_SELECTED_DOC_TYPE,
    SET_DOC_TYPE_LIST,
    DOC_TYPE_LIST_ERROR,
    UPDATE_FILES,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_ERROR,
    DEFAULT,
    LOADING_DOCS,
    NAME_VALID,
    EMAIL_VALID,
    SET_RECAPTCHA_TOKEN,
    SET_EXPIRED_RECAPTCHA,
    SET_SITE_KEY,
    HIDE_TOAST,
    RECAPTCHA_LOADED,
    DELETE_FILE,
    SHOW_INVALID_FILES,
    UPLOAD_STATUS_MAP,
    CANCEL_UPLOAD, FILE_UPLOAD_PROGRESS, FILE_UPLOAD_INIT,
    CHECK_FIELD_ERROR,
    FORM_PATH,
    VALID_FIELD,
    REQUIRED_FIELDS
} from "./types";


const initialState = {
    files: {},
    validFile: false,
    startUpload: false,
    showCancelMessage : false,
    cancelMessage: '',
    defaultDocType : true,
    docType: null,
    docTypeList : [],
    showUploadSuccess: false,
    successMessage: '',
    showUploadError: false,
    errorMessage: '',
    errorTitle: '',
    uploadedFileName: '',
    resetField:false,
    loading: false,
    validReCaptcha:false,
    siteKey: '',
    recaptchaToken: '',
    fileStatus: '',
    responseStatus: 0,
    recaptchaLoaded: false,
    uploadedFiles:[],
    invalidFiles: [],
    showInvalidFiles: false,
    warningType: UPLOAD_STATUS_MAP.INVALID,
    cancelUpload: false,
    name : false,
    email : false,
    reference: false,
    formPath: '/',
    validField: {},
    requiredFields: []
};

const fileUploadReducer = (state = initialState, action = {}) =>{

    const {type, payload} = action;

    switch (type) {
        case ACCEPTED_FILES:
            return{
                ...state,
                files: payload.files,
                validFile: payload.validFile,
                showUploadError: false,
                invalidFiles: payload.invalidFiles,
                warningType: UPLOAD_STATUS_MAP.INVALID

            };
        case REJECTED_FILES:
            return{
                ...state,
                invalidFiles: payload.files,
                showInvalidFiles: payload.showInvalidFiles,
                warningType: UPLOAD_STATUS_MAP.INVALID,
                showUploadError: false
            };
        case DELETE_FILE:
            return{
                ...state,
                files: payload.files,
                validFile: payload.validFile,
                showUploadError: false
            }

        case DEFAULT_DROP_AREA: {
            return{
                ...state,
                files: {},
                validFile: false,
                startUpload : false,
                showUploadError: false,
                recaptchaToken: '',
                showUploadSuccess: payload.showUploadSuccess,
                showCancelMessage: payload.showCancelMessage,
                invalidFiles: [],
                warningType: UPLOAD_STATUS_MAP.INVALID
            }
        }

        case HIDE_TOAST:
            return{
                ...state,
                showCancelMessage : false
            }

        case START_UPLOAD: {
            return{
                ...state,
                startUpload: true,
                invalidFiles: [],
                warningType: UPLOAD_STATUS_MAP.INVALID,
                cancelUpload: false
            }
        }
        case SET_SELECTED_DOC_TYPE: {
            return{
                ...state,
                defaultDocType : payload.default,
                docType: payload.docType
            }
        }
        case SET_DOC_TYPE_LIST: {
            return{
                ...state,
                docTypeList: payload.docTypes,
                loading: payload.loading
            }
        }
        case SET_RECAPTCHA_TOKEN: {
            return{
                ...state,
                validReCaptcha: true,
                showUploadError: false,
                recaptchaToken: payload.recaptchaToken
            }
        }

        case SET_EXPIRED_RECAPTCHA:{
            return{
                ...state,
                recaptchaToken: ''
            }
        }

        case RECAPTCHA_LOADED:{
            return{
                ...state,
                recaptchaLoaded : true
            }
        }
        case DOC_TYPE_LIST_ERROR: {
            return{
                ...state,
                docTypeList: payload.docTypes,
                loading: payload.loading,
                showUploadError: true,
                errorMessage: payload.errorMessage,
                errorTitle: 'Error',
                responseStatus: payload.responseStatus
            }
        }
        case UPDATE_FILES: {
            return{
                ...state,
                files : {
                    ...state.files,
                    [payload.fileName] : {
                        ...state.files[payload.fileName],
                        status: payload.update.status,
                        response: payload.update.response,
                        sortStatus: payload.update.sortStatus
                    }
                }
            }
        }

        case FILE_UPLOAD_PROGRESS : {
            return{
                ...state,
                files : {
                    ...state.files,
                    [payload.fileName] : {
                        ...state.files[payload.fileName],
                        progress: payload.progress
                    }
                }
            }
        }

        case FILE_UPLOAD_INIT : {
            return{
                ...state,
                files: payload.files
            }
        }

        case FILE_UPLOAD_SUCCESS: {
            return{
                ...state,
                showUploadSuccess: true
            }
        }

        case FILE_UPLOAD_ERROR: {
            return {
                ...state,
                showUploadError: true,
                errorMessage: payload.errorMessage,
                errorTitle: payload.title,
                uploadedFileName : payload.fileName,
                files: {},
                validFile: false,
                showCancelMessage : false,
                cancelMessage: '',
                startUpload : false,
                responseStatus: payload.responseStatus
            }
        }

        case LOADING_DOCS: {
            return{
                ...state,
                loading: true
            }
        }

        case VALID_FIELD: {
            return{
                ...state,
                validField : {...state.validField, [payload.field] : payload.valid}
            }
        }

        case SET_SITE_KEY: {
            return{
                ...state,
                siteKey: payload.siteKey
            }
        }

        case SHOW_INVALID_FILES: {
            return{
                ...state,
                showInvalidFiles: payload.show
            }
        }

        case UPLOAD_STATUS_MAP.MAX_FILE: {
            return{
                ...state,
                warningType: UPLOAD_STATUS_MAP.MAX_FILE,
                showUploadError: false
            }
        }

        case CANCEL_UPLOAD : {
            return{
                ...state,
                cancelUpload: true
            }
        }

        case CHECK_FIELD_ERROR: {
            return{
                ...state,
                [payload.field] : payload.check
            }
        }

        case FORM_PATH: {
            return{
                ...state,
                formPath: payload.path
            }
        }

        case REQUIRED_FIELDS: {
            return{
                ...state,
                requiredFields: payload.requiredFields
            }
        }

        case DEFAULT: {
            return {
                ...state,
                files: {},
                validFile: false,
                startUpload: false,
                showCancelMessage : false,
                cancelMessage: '',
                defaultDocType : true,
                docType: null,
                showUploadSuccess: false,
                showUploadError: false,
                uploadedFileName: '',
                resetField: true,
                recaptchaToken:'',
                responseStatus: 0,
                uploadedFiles:[],
                invalidFiles: [],
                warningType: UPLOAD_STATUS_MAP.INVALID,
                cancelUpload: false,
                validField: {}

            };
        }
        default:
            return state;
    }
}

export default fileUploadReducer;