import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { reduxForm } from 'redux-form';
import './fileupload.styles.scss';
import DropAreaFileUpload from './components/DropAreaFileUpload/';
import Button from '../../components/common/button/Button';
import UploadError from './components/UploadError';
import UploadSuccessful from './components/UploadSuccessful';
import UploadWarning from './components/UploadWarning'
import Recaptcha from 'react-recaptcha';
import {MAX_ACCEPTED_FILES} from "./types";
import UploadDetails from './components/UploadDetails';
import pushEvents from "../../analytics/pushEvents";
import {CustomAnalyticEvent, PATH_MAP} from "../../analytics/trackedEvents";

export const FILE_MAX_SIZE = 94371840;
export const FILE_MIN_SIZE = 1;
class FileUpload extends Component{

    componentDidMount(){
        this.props.getGoogleSiteKey();
        this.props.setFormPath(this.props.formPath)
        this.props.registerRequiredFields(this.props.uploadForm.userComponents)
    }

    verifyUser = (response) =>{

        this.props.onStartUpload();
        this.props.verifyCallback(response);
        if(this.recaptchaInstance){
            this.recaptchaInstance.reset();
        }
    }

    callback = () =>{
        this.props.onRecaptchaLoad();
    };

    startUpload = () =>{
        this.recaptchaInstance.execute();
        pushEvents(CustomAnalyticEvent[PATH_MAP[this.props.formPath]].upload)
    }

    checkForError = (event, newValue, previousValue, name) =>{
        this.props.checkForError(name, true)
    }

    resetAnyError = (event, name) =>{
        this.props.checkForError(name, false)
        pushEvents(CustomAnalyticEvent[PATH_MAP[this.props.formPath]][name])
    }

    setSelectedDocType = (doc)=>{
        this.props.onDocTypeSelect(doc)
        pushEvents(CustomAnalyticEvent[PATH_MAP[this.props.formPath]].documentType,
            doc.documentType.replace(/[\s/]/g, "_"))
    }

    processOnDrop = (acceptedFiles, rejectedFile)=>{
        this.props.onDrop(acceptedFiles, rejectedFile)
        pushEvents(CustomAnalyticEvent[PATH_MAP[this.props.formPath]].dropzone)
    }

    render(){
        const {
            uploadForm,
            validFile,
            startUpload,
            defaultDocType,
            loading,
            siteKey,
            errorMessage,
            errorTitle,
            responseStatus,
            recaptchaLoaded,
            validFileCount,
            fieldCheck,
            validField,

            checkFieldValidity,
            onCancelAllUpload,
            setDefaultAll,
            showUploadSuccess,
            showUploadError,
            resetField,
            expiredCallback,
            files
        } = this.props;

        let border = '';
        if(validFile){
            border = 'no-border';
        }

        let docType = defaultDocType;
        if(uploadForm.docTypeComponents.length === 0){
            docType = false;
        }

        let uploadButton = <Button id='upload' buttonText={uploadForm['uploadButton'].label} disabled={!validFile || docType || !validField || !recaptchaLoaded} onClick={this.startUpload}/>
        if(startUpload){
            uploadButton = <Button id='cancel' buttonText={uploadForm['uploadButton'].secondLabel} className='cancel-btn' onClick={onCancelAllUpload}/>
        }

        const uploadDetails = <UploadDetails docTypeComponents={uploadForm.docTypeComponents} userComponents={uploadForm.userComponents} checkForError={this.checkForError} resetAnyError={this.resetAnyError}
                                             startUpload={startUpload} resetField={resetField} checkFieldValidity={checkFieldValidity}
                                             fieldCheck={fieldCheck} onDocTypeSelect={this.setSelectedDocType} loading={loading}/>

        return(
            <Fragment>
                <div className='main-panel'>
                    <div className='title-panel'>
                        <span className='title'>{uploadForm.title}</span>
                    </div>
                    {showUploadSuccess ? (<UploadSuccessful uploadedFiles={files} handleSetToInitialState={setDefaultAll} disclaimer={uploadForm.disclaimer} />) :
                    (
                        <div className='uploading-panel'>
                            <div className='instruction-panel'>
                                <span className='instruction'>{uploadForm.description}</span>
                            </div>

                            {showUploadError ? (<div className='upload-error-panel'>
                                <UploadError errorMessage={errorMessage} title={errorTitle} responseStatus={responseStatus}/>
                            </div>) :(<div></div>)}

                            <div className='upload-error-panel'>
                                <UploadWarning/>
                            </div>

                            {uploadDetails}

                            <div className='file-count' style={{visibility: validFileCount > 0 ? 'visible' : 'hidden'}}>Files: {validFileCount} / {MAX_ACCEPTED_FILES}</div>
                            <div className="drag-and-drop ">
                                <Dropzone
                                    className={'drag-and-drop-area' + border}
                                    onDrop={this.processOnDrop}
                                    activeClassName="active"
                                    disabled={false}
                                    disableClick={startUpload}
                                    maxSize={FILE_MAX_SIZE}
                                    multiple={true}
                                    accept='application/pdf, image/jpeg, image/jpg, image/bmp, image/gif, image/tiff, image/png'
                                    minSize={FILE_MIN_SIZE}
                                >
                                    <DropAreaFileUpload/>
                                </Dropzone>
                            </div>

                            <div className='file-info'>
                                <p>You can upload a file that is maximum 90 MB in size with a file type of PDF, PNG, JPEG, JPG, BMP, TIFF, and GIF.</p>
                            </div>

                            <div  className='button-panel'>
                                {uploadButton}
                            </div>
                        </div>
                    )}

                    {(siteKey !== '') ?
                        <div>
                            <div className="recaptcha-position">
                                <Recaptcha
                                    ref={e => (this.recaptchaInstance = e)}
                                    sitekey={siteKey}
                                    size="invisible"
                                    render="explicit"
                                    theme="light"
                                    verifyCallback={this.verifyUser}
                                    expiredCallback={expiredCallback}
                                    expiredCallbackName="expiredCallback"
                                    onloadCallback={this.callback}
                                />

                            </div>
                        </div>
                        : null
                    }

                </div>


            </Fragment>
        );
    }
}

FileUpload.propTypes = {

    docTypeList : PropTypes.arrayOf(PropTypes.shape({
        documentType: PropTypes.string,
        documentId: PropTypes.any
    })),
    fieldCheck : PropTypes.shape({
        checkName: PropTypes.bool,
        checkEmail: PropTypes.bool
    }),

    cancelMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    errorTitle: PropTypes.string,
    successMessage: PropTypes.string,
    uploadedFileName: PropTypes.string,

    responseStatus: PropTypes.number,

    defaultDocType: PropTypes.bool,
    loading: PropTypes.bool,
    resetField: PropTypes.bool,
    startUpload : PropTypes.bool,
    showCancelMessage: PropTypes.bool,
    showUploadError: PropTypes.bool,
    showUploadSuccess: PropTypes.bool,
    validFile : PropTypes.bool,
    validForm: PropTypes.bool,
    validReCaptcha: PropTypes.bool,
    recaptchaLoaded: PropTypes.bool,
    loadDocTypes: PropTypes.func,
    onCancelUpload: PropTypes.func,
    onCloseCancelMessage: PropTypes.func,
    onDeleteFile: PropTypes.func,
    onDocTypeSelect: PropTypes.func,
    onDrop: PropTypes.func,
    onRecaptchaLoad: PropTypes.func,
    onStartUpload: PropTypes.func,
    setDefaultAll: PropTypes.func,
    verifyCallback: PropTypes.func,
    expiredCallback: PropTypes.func
};
export {FileUpload};
export default reduxForm({
    form: 'FileUploadForm',
    destroyOnUnmount: false,
    asyncBlurFields: [
        'email',
    ]// a unique identifier for this form
})(FileUpload);