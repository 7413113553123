/* eslint-disable no-underscore-dangle, no-undef, no-restricted-globals */
import http from './service/http';
import history from "./history";
import pushAnalytics from "./analytics/pushAnalytics";

export const getApplicationVersionNumber = async () => {

    const versionUrl = '/version';
    try {
        const {data} = await http.get(versionUrl);
        return data;
    }catch (error) {
        return {versionNumber: '0.0'};
    }
}

export const getConfigData = async (formPath) =>  {
    try {
        const {data} = await http.post('/config', {formPath: formPath});
        return data;
    }catch (error) {
        return {configDetails: null, message: 'Upload Form not found.'}
    }

}

export const getAnalyticsConfigData = async() => {
    try {
        const response = await http.get('/analytics');
        return response.data;
    } catch (error) {
        return {url: null, message: 'No analytics config data found'};
    }
}


export async function initAnalytics() {
    const data = await getAnalyticsConfigData();
    window._paq = window._paq || [];
    _paq.push(['enableLinkTracking']);
    _paq.push(['setTrackerUrl', `${data.analytics.url}matomo.php`]);
    _paq.push(['setSiteId', data.analytics.siteId]);
    _paq.push(['setDocumentTitle', 'FileDrop']);
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = `${data.analytics.url}matomo.js`;
    s.parentNode.insertBefore(g, s);
    pushAnalytics(history.location.pathname, data.analytics.clientIp);

}

//sample json config
const getAgentNoticeData = () =>{
    const configData = {
        title: "AGENT NOTICE SUBMISSION",
        description: "Agent Notice Submission file drop can be used to send Citco information for further processing. Please complete the information below and click ‘Upload’.",
        userComponents: [
            {
                type: "text",
                props: {
                    id: "name",
                    label: "Sender Name",
                    single: false,
                    validate: ['MAX_LENGTH_50', 'CHAR_START', 'VALID_CHARS']
                },
                level: 1,
                show: true,
                panel: 'user'
            },{
                type: "email",
                props: {
                    id: "email",
                    label: "Sender Email",
                    single: false,
                    validate: ['MAX_LENGTH_100', 'VALID_EMAIL']
                },
                level: 1,
                show: true,
                panel: 'user'

            },{
                type: "text",
                props: {
                    id: "reference",
                    label: "Reference",
                    single: true,
                    validate: ['MAX_LENGTH_1000']
                },
                level: 2,
                show: true,
                panel: 'user'

            }
        ],
        docTypeComponents: [
            {
                type: "dropdown",
                props : {
                    id: "docType",
                    label: "* Document Type",
                    initialValue: {documentType: 'Select Document Type', documentId: -1},
                    list: [{documentType: 'TEST', documentId: 1}, {documentType: 'TEST 2', documentId: 2}]
                },
                show: true,
                panel: 'docType'
            }
        ],
        dropzone : {
            show: true
        },
        uploadButton: {
            label: "UPLOAD",
            secondLabel: "CANCEL"
        }
    };

    return configData;
}