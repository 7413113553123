import filesize from 'filesize';
import _ from 'lodash';
import {UPLOAD_STATUS_MAP} from "../scenes/file-upload/types";
import { FILE_MAX_SIZE, FILE_MIN_SIZE } from "../scenes/file-upload/FileUpload";

export const acceptedFileList = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'application/pdf',
];

export const previewFileList = [
    'png',
    'jpg',
    'jpeg',
    'bmp',
    'pdf',
];

export const acceptedFileExtensions = [
    'png',
    'jpg',
    'jpeg',
    'bmp',
    'pdf',
    'tiff',
    'gif'
];

export const checkReasonForError = (file) =>{
    let invalidFile = acceptedFileExtensions.indexOf(getFileExtension(file.name)) > -1;
    if(!invalidFile)
        return "This file has an incorrect format."

    if(file.size > FILE_MAX_SIZE )
        return "This file is too large."

    if(file.size < FILE_MIN_SIZE)
        return "This file has 0 byte."
};

export const getFileExtension = filename => filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length,
);

const size = filesize.partial();

export const fileSize = bytes => size(bytes);

export const getUniqueFiles = (name, ...arrs) => [].concat(...arrs).reduce((current, added) => !current.filter(file => added[name] === file[name]).length ? [...current, added] : current, []);

export const createAcceptedFileObject = (addedFiles, stagedFiles) =>{

    let acceptedFilesStore = {};
    if(!_.isEmpty(stagedFiles)) {
        acceptedFilesStore = _.cloneDeep(stagedFiles);
        for(const file of addedFiles){
            if (!_.has(acceptedFilesStore, file.name)) {
                acceptedFilesStore[file.name] = {
                    progress: 0,
                    file: file,
                    status: UPLOAD_STATUS_MAP.DEFAULT,
                    response: {},
                    sortStatus: ''
                }
            }
        }
    }else{
        for(const file of addedFiles){
            acceptedFilesStore[file.name] = {
                progress: 0,
                file: file,
                status: UPLOAD_STATUS_MAP.DEFAULT,
                response: {},
                sortStatus: ''

            }
        }
    }

    return acceptedFilesStore;
}

export const initAcceptedFiles = (acceptedFiles) =>{

    const newAccepted = _.keys(acceptedFiles).reduce((acc, key) => ({
        ...acc,
        [key]: {
            ...acceptedFiles[key],
            status: UPLOAD_STATUS_MAP.STARTED
        },
    }), {});

    return newAccepted;
}

export const getFileName = (rejectedFiles) =>{

    return _.map(rejectedFiles, _.property('name')).join();

}
