export const ACCEPTED_FILES = 'ACCEPTED_FILES';
export const REJECTED_FILES = 'REJECTED_FILES';
export const DEFAULT_DROP_AREA = 'DEFAULT_DROP_AREA';
export const START_UPLOAD = 'START_UPLOAD';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const SET_SELECTED_DOC_TYPE = 'SET_SELECTED_DOC_TYPE';
export const SET_DOC_TYPE_LIST = 'SET_DOC_TYPE_LIST';
export const DOC_TYPE_LIST_ERROR = 'DOC_TYPE_LIST_ERROR';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_INIT = 'FILE_UPLOAD_INIT';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const UPDATE_FILES = 'UPDATE_FILES';
export const DEFAULT = 'DEFAULT';
export const LOADING_DOCS = 'LOADING_DOCS';
export const EMAIL_VALID = 'EMAIL_VALID';
export const NAME_VALID = 'NAME_VALID';
export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN';
export const SET_EXPIRED_RECAPTCHA = 'SET_EXPIRED_RECAPTCHA';
export const SET_SITE_KEY = 'SET_SITE_KEY';
export const INVALID_RECAPTCHA = 'INVALID_RECAPTCHA';
export const HIDE_RECAPTCHA = 'HIDE_RECAPTCHA';
export const HIDE_TOAST = 'HIDE_TOAST';
export const RECAPTCHA_LOADED = 'RECAPTCHA_LOADED';
export const DELETE_FILE = 'DELETE_FILE';
export const SHOW_INVALID_FILES = 'SHOW_INVALID_FILES';
export const CHECK_FIELD_ERROR = 'CHECK_FIELD_ERROR';
export const FORM_PATH = 'FORM_PATH';
export const VALID_FIELD = 'VALID_FIELD';
export const REQUIRED_FIELDS = 'REQUIRED_FIELDS';
export const MAX_ACCEPTED_FILES = 50;

export const UPLOAD_STATUS_MAP = {
    DEFAULT : 'DEFAULT',
    STARTED : 'STARTED',
    CANCELED : 'CANCELED',
    FAILED : 'FAILED',
    SUCCESS : 'SUCCESS',
    INVALID: 'INVALID',
    MAX_FILE: 'MAX_FILE',
    MALICIOUS_FILE: 'MALICIOUS_FILE',
    SCANNING: 'SCANNING',
    FILE_RECEIVED: 'FILE_RECEIVED'

};

export const SORT_STATUS_MAP = {

    CANCELED : 'C',
    FAILED : 'C-F',
    FILE_RECEIVED : 'A-S',
    MALICIOUS_FILE: 'B-Q'
}

export const FORM_TYPES = {
    MAIN : '',
    NOTICE: 'agent_notice'

}

