import React, { Fragment } from 'react';
import "./UploadDetails.styles.scss"
import WarningField from "../../../form/Form";
import {
    checkLocalPartLength, maxLength1000, maxLength100, maxLength50,
    startWithCharName, validChars, validEmail, required
} from "../../../../utils/validator";
import {Field} from "redux-form";
import Select from "../../../select/Select";
import Icon from "../../../../components/common/FontAwesomeIcon"
import _ from 'lodash';

export const VALIDATION_MAP = {
    "EMAIL_LOCAL_PART_LENGTH" : checkLocalPartLength,
    "MAX_LENGTH_50" : maxLength50,
    "MAX_LENGTH_100" : maxLength100,
    "MAX_LENGTH_1000" : maxLength1000,
    "CHAR_START": startWithCharName,
    "VALID_CHARS": validChars,
    "VALID_EMAIL": validEmail,
    "REQUIRED" : required
}

export const UploadDetails = ({userComponents, docTypeComponents, checkForError, resetAnyError,
                       startUpload, resetField, checkFieldValidity, fieldCheck ,
                       onDocTypeSelect, loading}) => {

    let textfieldProps = {disabled:startUpload, reset:resetField, validateForm: checkFieldValidity,
        }
    let emailFieldProps = {disabled:startUpload, reset:resetField, validateForm: checkFieldValidity,
       }

    const processByLevel = (field) =>{

        switch (field.type) {

            case "text":
                const updatedTextProps = {...textfieldProps, single: field.props.single, required: (field.props.required ? true: false), withError: fieldCheck[field.props.id]}
                return <Textfield key={field.props.id} field={field.props} eventProps={updatedTextProps}
                                  checkForError={checkForError} resetAnyError={resetAnyError}/>
                break;
            case "email":
                const updatedEmailProps = {...emailFieldProps, single: field.props.single, required: (field.props.required ? true: false), withError: fieldCheck[field.props.id]}
                return <EmailField key={field.props.id} field={field.props} eventProps={updatedEmailProps}
                                   checkForError={checkForError} resetAnyError={resetAnyError}/>
                break;
            default:
                return null;
        }

    }

    let userPanel = [];
    let level1 = [];
    let level2 = [];
    userComponents.forEach((c) =>{
        if(c.level === 1){
            level1.push(processByLevel(c))
        }else if(c.level === 2){
            level2.push(processByLevel(c))
        }
    });

    if(level1.length > 0){
        userPanel.push(<div key='lvl-1' className='user-level'>{level1}</div>)
    }
    if(level2.length > 0){
        userPanel.push(<div key='lvl-2' className='user-level'>{level2}</div>)
    }

    let noBorder = ' no-border';
    if(docTypeComponents.length > 0){
        noBorder = '';
    }

    return (
        <Fragment>
            <div className={'user-info'  + noBorder}>
                {userPanel}
            </div>
            <div className='file-type-panel'>
                {docTypeComponents.map((field) => {

                    switch (field.type) {
                        case "dropdown":
                            return <Dropdown key={field.props.id} field={field.props} onDocTypeSelect={onDocTypeSelect} startUpload={startUpload} loading={loading} />
                            break;
                        default:
                            return null;
                    }

                })}
            </div>
        </Fragment>
    )

}

export const getValidations = (validations) =>{
    const fieldValidation = [];
    if(validations.length > 0){
        for (let i=0; i < validations.length; i++){
            if(_.has(VALIDATION_MAP, validations[i])){
                fieldValidation.push(VALIDATION_MAP[validations[i]])
            }
        }
    }
    return fieldValidation;

}

export const Textfield = ({field, eventProps, checkForError, resetAnyError}) =>{

    return (
        <Field
            name={field.id}
            type="text"
            label={<CustomLabel details={field}/>}
            component={WarningField}
            onBlur = {checkForError}
            onFocus={resetAnyError}
            props={{...eventProps}}
            validate={getValidations(field.validate)}
        />
    )
}

export const EmailField = ({field, eventProps, checkForError, resetAnyError}) =>{
    return(
        <Field
            name={field.id}
            type="input"
            component={WarningField}
            onBlur = {checkForError}
            onFocus={resetAnyError}
            label={<CustomLabel details={field} />}
            props={{...eventProps}}
            validate={getValidations(field.validate)}
        />
    )
}

export const Dropdown = ({field, onDocTypeSelect, startUpload, loading}) =>{
    const {label, initialValue, list} = field
    return(
        <Select
            label={label}
            initialValue={{...initialValue}}
            list={list}
            // Do not remove it. This is being used for automated test.
            id="docList"
            handleSelect={onDocTypeSelect}
            isDisabled={startUpload}
            loading={loading}
        />
    )
}

export const CustomLabel = ({details}) =>{
    const {label, required, infoIcon, infoMessage} = details;
    if(required && infoIcon){
        return(
            <Fragment>
                <span>{"* " + label}</span>
                <Icon id='info-message' name="info-circle" tooltip={infoMessage} tooltipClassName='info-message'/>
            </Fragment>

        )
    }else if(required){
        return(
            <span>{"* " + label}</span>
        )
    }else{
        return(
            <span>{label}</span>
        )
    }
}

export default UploadDetails;