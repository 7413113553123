import React from 'react';
import { connect } from 'react-redux';


const ListView = ({items, onSelect}) => {

    return (
    <ul>
        {items.map(item => <ItemContainer key={item.documentId} item={item} onSelect={onSelect}/>)}
    </ul>
)}

const ItemView = ({item, onClick}) => (

    <li onClick={onClick}>
            <span id={'select_' + item.documentId}>{item.documentType}</span>
    </li>
)

const mapDispatchToProps = (dispatch, {item, onSelect}) => ({

    onClick: (event) => {
        event.preventDefault()
        onSelect(item);
    }
})

const ItemContainer = connect(null, mapDispatchToProps)(ItemView)

export default ListView;