import React from 'react';
import Button from '../../../../components/common/button/Button';
import PropTypes from 'prop-types';
import './uploadsuccessful.styles.scss';
import {UPLOAD_STATUS_MAP,SORT_STATUS_MAP} from "../../types";
import _ from 'lodash';
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactTooltip from "react-tooltip";

export const UploadSuccessful = ({handleSetToInitialState, uploadedFiles, disclaimer}) => {

    let corruptedCss = '';
    let corruptedIconCss = '';

    let successFiles =0;
    let errorFiles = 0;
    let quarantinedFiles = 0;
    let data = [];

    const tableStyle = {
        border: "none",
        boxShadow: "none"
    };

    const formatWithIcon = (status, message) =>{
        let iconUsed;
        let tooltip = ''
        let type = ''
        let show = false
        if(status === SORT_STATUS_MAP.MALICIOUS_FILE){
            quarantinedFiles += 1;
            iconUsed = "fa-exclamation-triangle";
            tooltip= message
            type = 'warning'
            show = true
        }else if (status=== SORT_STATUS_MAP.FAILED) {
            errorFiles += 1;
            iconUsed = "fa-exclamation-circle";
            if(message && message !== "") {
                tooltip= message
                type = 'error'
                show = true
            }
        }else if (status=== SORT_STATUS_MAP.FILE_RECEIVED){
            successFiles += 1;
            iconUsed = "fa-check-circle"
        }

        const randomId = String(Math.random());
        return(
            <>
                <span className="icon-container" ><i data-for={randomId} className={"fa-2x fa "+iconUsed} data-tip={tooltip}></i> </span>
                <ReactTooltip id={randomId} place='right' effect='solid' type={type} show={show}
                            delayHide={0}/>
            </>
        );
    }

    _.forOwn(uploadedFiles, (file, key) => {
        if(file.status !== UPLOAD_STATUS_MAP.CANCELED) {
            const response = file.response;
            data.push({fileName: key, refId:response.uploadRequestId, sortStatus: file.sortStatus, status: formatWithIcon(file.sortStatus), message: response.message})
        }

    })

    let tblUploadedFiles = (
        <div>
            <ReactTable
                style={tableStyle}
                data={data}
                columns={[
                    {
                        Header: () => (
                            <div
                                style={{
                                    textAlign:"left"
                                }}
                            >File Name</div>),
                        accessor: 'fileName',
                        width:320,
                        Cell: cellContent  => (
                            <div title={cellContent.value} className="rt-cell-with-title">{cellContent.value}</div>)
                    },
                    {
                        Header: () => (
                            <div
                                style={{
                                    textAlign:"left"
                                }}
                            >Ref ID</div>),
                        accessor: 'refId',
                        width:90,
                        Cell: cellContent  => (<div title={cellContent.value} className="rt-cell-with-title">{cellContent.value}</div>)
                    },{
                        Header: () => (
                            <div
                                style={{
                                    textAlign:"center"
                                }}

                            >Status</div>),
                        accessor: 'sortStatus',
                        width:70,
                        Cell: cellContent  =>(<div style={{textAlign:'center'}}>{formatWithIcon(cellContent.value, cellContent.original.message)}</div>)

                    },
                ]}
                defaultSorted={[
                    {
                        id: 'sortStatus',
                        desc: false
                    }
                ]}
                showPagination={false}
                defaultPageSize={data.length}
                resizable={false}
            />
            <br />
            <h3 style={{float:"right", textTransform:"uppercase",paddingRight:"13px"}}>Files Uploaded : {successFiles + quarantinedFiles + errorFiles}</h3>
        </div>

    );

    let uploadMessage = (<div>
        <div className={"page-title"}><h2>File Upload Summary</h2></div>

        {successFiles !== 0 ?(<span className="status-pill success">SUCCESS ({successFiles})</span>):''}
        {successFiles !== 0 ?('\u00A0'):''}

        {quarantinedFiles !== 0 ?(<span className="status-pill quarantined">MALICIOUS FILES ({quarantinedFiles})</span>):''}
        {quarantinedFiles !== 0 ?('\u00A0'):''}

        {errorFiles !== 0 ?(<span className="status-pill error">ERROR ({errorFiles})</span>):''}
        {errorFiles !== 0 ?('\u00A0'):''}

        {!_.isEmpty(uploadedFiles) ? (tblUploadedFiles):(<div className="no-uploaded-files">
            No Uploaded Files
        </div>)}

    </div>);
    let refMessage = <div className="content-container" style={{wordBreak:'break-word'}}>{uploadMessage}
    </div>
    /*if(fileStatus === VIRUS_FOUND){
        corruptedCss = 'corrupted-file';
        corruptedIconCss = 'corrupted-icon'
        iconCss = 'fa-2x fa fa-exclamation-circle';
        uploadMessage = 'Your file "'+ uploadedFileName + '" has been quarantined.'
        refMessage = <p style={{wordBreak:'break-word'}}>{uploadMessage}<br/>
            {successMessage} Please contact <a href="mailto:citcohelp@citco.com">citcohelp@citco.com</a> with this reference ID for further assistance.
        </p>
    }


*/
    return (
        <div className='upload-successful-container'>
            <div className={'successful-upload-box '  + corruptedIconCss}>
                <div className={"line-top-border " + corruptedCss}></div>
                <div className={"container" }>
                    <div className="message-container">

                        {refMessage}

                    </div>

                    <div className="btn-container">
                        <Button id="upload-another-file" className="btn-c1-primary" onClick={handleSetToInitialState} >Upload Another File</Button>
                    </div>
                </div>
            </div>

            <div className='terms-and-conditions-container'>
                <p>{disclaimer}</p>
            </div>
        </div>
    )};



UploadSuccessful.propTypes = {
    uploadedFileName: PropTypes.string,
    handleSetToInitialState:PropTypes.func
};

UploadSuccessful.defaultProps = {
    uploadedFileName: '',
    handleSetToInitialState:''
};

export default UploadSuccessful;