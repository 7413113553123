import { connect } from 'react-redux';
import Toasts from '../../components/Toasts';
import { killToast } from './actions';

const mapStateToProps = state => ({
  toasts: state.toasts,
});

const mapDispatchToProps = dispatch => ({
  killToast(id) {
    dispatch(killToast(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
