import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './select.css';
import Menu from './components/Menu'

class Select extends Component {

    constructor(props){
        super(props);

        this.state = { selected : this.props.initialValue,
            displayData : false,
            dropIcon : 'fa fa-angle-down'
        };
        //[constructor] the only place where you can set the state of a field
    }

    _showData = (e) => {
        if(!this.props.isDisabled){
            var display = this.state.displayData;
            var icon = this.state.dropIcon;
            if(display === true){
                display = false;
                icon = 'fa fa-angle-down'
            }else if(display === false){
                display = true;
                icon = 'fa fa-angle-up'
            }

            this.setState({displayData : display, dropIcon: icon});
            document.addEventListener("click", this._hide);
        }

    }

    _hide = (event) => {
        event.preventDefault();
        if(event.target.id.indexOf('select_' + this.props.id + '_') === -1){
            this.setState({ displayData: false, dropIcon:'fa fa-angle-down'});
            document.removeEventListener("click", this._hide);
        }

    }

    _setSelected = (item) =>{
        this.setState({selected: item, dropIcon: 'fa fa-angle-down'})
        this.props.handleSelect(item);

    }

    render() {

        let data = null;
        if(this.state.displayData){
            data = <Menu id={this.props.id} isLoading={this.props.loading} items={this.props.list} onSelect={this._setSelected}/>
        }
        return (

            <div id={'select_' + this.props.id + '_mainContainer'} className={'select-content disabled_' + this.props.isDisabled} >
                <label id={'select_' + this.props.id + '_label'} className='select-label'>{this.props.label}</label>
                <div className='select' id={'select_' + this.props.id + '_panel'} >
                    <button id={'select_' + this.props.id + '_displayBtn'}  onClick={this._showData} disabled={this.props.isDisabled}>
                        {this.state.selected.documentType}
                    </button>
                    <i id={'select_' + this.props.id + '_angleIcon'} className={this.state.dropIcon} onClick={this._showData}/>
                    {data}
                </div>
            </div>
        );

    }


}

Select.propTypes = {
    id : PropTypes.string,
    loading : PropTypes.bool,
    label: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({
        documentType: PropTypes.string,
        documentId: PropTypes.any
    })),
    setSelected: PropTypes.func, // handle selected value
    initialValue : PropTypes.shape({
        documentType: PropTypes.string,
        documentId: PropTypes.any
    }),
    isDisabled:PropTypes.bool

}

export default Select;