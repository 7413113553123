export const analyticPaths = [
    {
        urlPathRegex: new RegExp('/$'),
        exampleUrl: '/',
        desc: 'Tracks user clicks across Main IR Filedrop page',
        analyticsPage: '/',
    },
    {
        urlPathRegex: new RegExp('/agent_notice$'),
        exampleUrl: '/agent_notice',
        desc: 'tracks user clicks across Filedrop Loan Agent page',
        analyticsPage: '/agent_notice',
    },
    {
        urlPathRegex: new RegExp('/borrower$'),
        exampleUrl: '/borrower',
        desc: 'Tracks user clicks across Filedrop Loan Borrower page',
        analyticsPage: '/borrower',
    },
    {
        urlPathRegex: new RegExp('/credit_agreement$'),
        exampleUrl: '/credit_agreement',
        desc: 'Tracks user clicks across Filedrop Loan Credit Agreement page',
        analyticsPage: '/credit_agreement',
    },
];

export default analyticPaths;