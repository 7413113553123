import React, {Fragment} from 'react';
import './UploadWarning.styles.scss';

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";
import {checkReasonForError} from "../../../../utils/fileUtil";

export const InvalidFile = ({invalidFiles}) => {

    const tableStyle = {
        border: "none",
        boxShadow: "none"
    };
    let data = [];
    invalidFiles.forEach((file) =>{
        let reason = checkReasonForError(file);
        data.push({fileName:file.name, reason:reason});
    });

    const tblIvalidFiles = (
        <div>
            <ReactTable
                style={tableStyle}
                data={data}
                columns={[
                    {
                        Header: () => (
                            <div
                                style={{
                                    textAlign:"left"
                                }}
                            >File Name</div>),
                        accessor: 'fileName',
                        Cell: cellContent  => (
                            <div title={cellContent.value} className="rt-cell-with-title">{cellContent.value}</div>)
                    },
                    {
                        Header: () => (
                            <div
                                style={{
                                    textAlign:"left"
                                }}
                            >Reason</div>),
                        accessor: 'reason',
                        Cell: cellContent  => (
                            <div title={cellContent.value} className="rt-cell-with-title">{cellContent.value}</div>)
                    }
                ]}
                defaultSorted={[
                    {
                        id: 'fileName',
                        desc: false
                    }
                ]}
                showPagination={false}
                defaultPageSize={data.length}
                resizable={false}
            />
        </div>

    );


    return (
        <Fragment>
            <p>The following files cannot be uploaded: </p>
            {tblIvalidFiles}
        </Fragment>
    )
}

export default InvalidFile;