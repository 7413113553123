import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../components/common/FontAwesomeIcon';

export const FileNotifications = ({ iconName, message, className }) => (
  <Fragment>
    <Icon name={iconName} className={className} size="5x" />
    <p>{message}</p>
  </Fragment>
);

FileNotifications.propTypes = {
  iconName: PropTypes.string.isRequired,
  message: PropTypes.string,
  className: PropTypes.string,
};

FileNotifications.defaultProps = {
  className: null,
  message: null,
};

export default FileNotifications;
