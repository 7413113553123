import {combineReducers} from 'redux';
import selectReducer from './scenes/select/reducer';
import fileUploadReducer from './scenes/file-upload/reducer';
import { reducer as reduxFormReducer } from 'redux-form';
import toastReducer from "./scenes/Toasts/reducer";

const RootReducer = combineReducers({
    selectReducer : selectReducer,
    fileUpload : fileUploadReducer,
    toasts : toastReducer,
    form: reduxFormReducer
});

export default RootReducer;

