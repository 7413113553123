import React from 'react';
import { FileNotifications } from '../FileNotifications';
import ValidFile from '../ValidFile/ValidFile'
import _ from "lodash";


export const DropAreaFileUpload = ({
   validFile,
   files,
   startUpload,
   progress,
   onDeleteFile,
   onCancelUpload
}) => {

      if(_.isEmpty(files)){
          return (
              <FileNotifications
                  iconName="cloud-upload"
                  message="Drag and drop your file or click here to select from your computer to upload"
              />
          );
      }else{

          let validFiles = [];
          let totalFiles = _.keys(files).length;
          _.forOwn(files, (detail, key) => {
              validFiles.push(<ValidFile key={key} file={detail.file}
                         handleDelete={onDeleteFile}
                         status={detail.status}
                         progress={detail.progress}
                         handleCancel={onCancelUpload}
                         showBorder={validFiles.length < (totalFiles - 1) ? true : false}
              />)

          });

          return (<div className='valid-file-panel' >
                    {validFiles}
                 </div>);
      }
};


export default DropAreaFileUpload;
