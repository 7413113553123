import PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({ value }) => (
  <div
    className="progress"
    role="progressbar"
    aria-valuenow={value}
    aria-valuemin="0"
    aria-valuetext={`${value} Percent`}
    aria-valuemax="100"
  >
    <span className="progress-meter" style={{ width: `${value}%` }}>
      <p className="progress-meter-text">
        {`${value}%`}
      </p>
    </span>
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  value: 0,
};

export default ProgressBar;
